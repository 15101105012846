import React, { useState, useEffect } from "react";
import Select from "react-select";
import { UserAssistants } from "../pages/api";
import { getThreadID } from "../pages/api";

function CreateNewChat({
  setNewChatName,
  newChatName,
  newChatStatus,
  setNewChatStatus,
  setChats,
  chats,
}) {
  const [selectedOption, setSelectedOption] = useState();
  let choosedOption;

  const handleChange_ServiceType = (selectedOption) => {
    setSelectedOption(selectedOption);
    choosedOption = selectedOption.value;
    return choosedOption;
  };
  const SelectMode = () => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
  
    useEffect(() => {
      const fetchOptions = async () => {
        const data = await UserAssistants();
        setOptions(data);
      };
      fetchOptions();
    }, []);
  
    const handleChange_ServiceType = (event) => {
      setSelectedOption(event);
      // Дополнительная логика при смене опции
    };
  
    return (
      <Select
        name="serviceType"
        data-flag="0"
        value={selectedOption}
        onChange={handleChange_ServiceType}
        options={options}
        placeholder="Выберите режим"
      />
    );
  };

  const cancelNewChat = () => {
    setNewChatStatus(0);
    setSelectedOption("");
    setNewChatName("");
    let overlayFullScreen = document.querySelector(".overlayFullScreen");
    overlayFullScreen.remove();
  };

  const saveNewChat = async() => {
    const modename = handleChange_ServiceType(selectedOption);
    const username = localStorage.getItem('username')

    let thread_id = await getThreadID(modename, username, newChatName)
    
    console.log(thread_id)
    const newChat = {
      Name: newChatName,
      ID: thread_id.data,
      messages: [],
      mode: modename,
    };
    setChats([newChat, ...chats]);

    setNewChatName("");
    setSelectedOption("");
    setNewChatStatus(0);

    let overlayFullScreen = document.querySelector(".overlayFullScreen");
    overlayFullScreen.remove();
  };

  const handleCloseNewChat = () => {
    setNewChatStatus(0);
    let overlayFullScreen = document.querySelector(".overlayFullScreen");
    overlayFullScreen.remove();
  };
  return (
    <div
      className={
        "createNewChat" + (newChatStatus ? " active_createNewChat" : "")
      }
    >
      <div className="row">
        <h3>Создать новый чат</h3>
        <button onClick={handleCloseNewChat}>
          <img src={process.env.PUBLIC_URL + "/img/svg/close.svg"} alt="" />
        </button>
      </div>

      <div className="block">
        <p>Название чата</p>
        <input
          type="text"
          value={newChatName}
          onInput={(e) => {
            setNewChatName(e.target.value);
          }}
        />
      </div>

      <div className="block">
        <p>Режим</p>
        <SelectMode />
      </div>

      <div className="row-buttons">
        <button className="cancelNewChat-btn" onClick={cancelNewChat}>
          Отмена
        </button>
        <button className="saveNewChat-btn" onClick={saveNewChat}>
          Сохранить
        </button>
      </div>
    </div>
  );
}

export default CreateNewChat;
