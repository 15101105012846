import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangePass } from "../pages/api";

function EditUserData({
  userSettingsStatus,
  setUserSettingsStatus,
  userSettings,
  setUserSetting,
}) {
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState(userSettings[0].Name);
  const navigate = useNavigate();

  const handleChangeUserName = (e) => {
    setUserName(e.target.value);
  };

  const userNameInput = document.querySelector(".editUserData .userNameInput");
  const passwordInput = document.querySelector(".editUserData .passwordInput");
  const closeUserEdit = () => {
    setUserSettingsStatus(0);
    let overlayFullScreen = document.querySelector(".overlayFullScreen");
    overlayFullScreen.remove();

    setPassword("");
  };
  const saveUserEdit = () => {
    if (password.length < 5 && password.length > 0) {
      passwordInput.style.borderColor = "#ff0000";
      setTimeout(() => {
        passwordInput.style.borderColor = "#00000026";
      }, 2000);
      return;
    }

    if (userName.length < 3) {
      userNameInput.style.borderColor = "#ff0000";
      setTimeout(() => {
        userNameInput.style.borderColor = "#00000026";
      }, 2000);
      return;
    }

    

    setUserSetting((prevSettings) => ({
      ...prevSettings,
      [0]: {
        ...prevSettings[0],
        Name: userName,
        Password: password.length > 0 ? password : prevSettings[0].Password,
      },
    }));
    ChangePass(localStorage.getItem('username'), password)
    closeUserEdit();
  };
  function logoutUser () {
    let overlayFullScreen = document.querySelector(".overlayFullScreen");
    localStorage.clear()
    overlayFullScreen.remove()
    navigate('/')

  }

  return (
    <div
      className={
        userSettingsStatus ? " activeUserEdit editUserData" : "editUserData"
      }
    >
      <div className="row">
        <h3>Настройки пользователя</h3>
        <button onClick={closeUserEdit}>
          <img src={process.env.PUBLIC_URL + "/img/svg/close.svg"} alt="" />
        </button>
      </div>
      <div className="block">
        <p>Имя пользователя</p>
        <input
        readOnly
          type="text"
          className="userNameInput"
          value={userName}
          onInput={(e) => handleChangeUserName(e)}
        />
      </div>
      <div className="block">
        <p>Изменить пароль</p>
        <input
          type="password"
          className="mt-1 passwordInput"
          placeholder="Новый пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="row-buttons">
        <button className="cancelNewUser-btn" onClick={closeUserEdit}>
          Отмена
        </button>
        <button className="saveNewUser-btn" onClick={saveUserEdit}>
          Сохранить
        </button>
        <button className="logout-btn" onClick={logoutUser}>
          Выйти
        </button>
      </div>
    </div>
  );
}

export default EditUserData;
