import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import { Navigate } from "react-router-dom";

async function logoutFromAcc(event) {
  localStorage.clear()
  return <Navigate to="/" />
}

function PersonalData() {
  function showCurrentSetting(event) {
    const currentButton = event.currentTarget;
    const allButtons = document.querySelectorAll(
      ".changePersonalData_modalWindow .left button"
    );
    const allBlocks = document.querySelectorAll(".settings-block");
    const needBlock = document.querySelector(currentButton.value);

    allBlocks.forEach((block) => {
      block.classList.remove("activeSettingsBlock");
    });
    allButtons.forEach((button) => {
      button.classList.remove("activeButton");
    });
    currentButton.classList.add("activeButton");
    needBlock.classList.add("activeSettingsBlock");

    const editUser = document.querySelector(".editUser_modalWindow");
    editUser.classList.remove("activeEditUser");

    const addUser = document.querySelector(".addNewUserEditor");
    addUser.classList.remove("activeAddNewUserEditor");
  }


  function saveChanges() {
    const NewName = document.querySelector(".NewName");
    const AsideName = document.querySelector("button.changeData span");
    const SaveValue = NewName.value;

    if (NewName.value.length >= 6) {
      AsideName.innerHTML = NewName.value;
      closeModalWindow();
    } else {
      NewName.style.borderColor = "#ff0000";
      setTimeout(() => {
        NewName.style.borderColor = "#00000026";
        NewName.value = SaveValue;
      }, 3000);
    }
  }

  function closeModalWindow() {
    const popUp = document.querySelector(".changePersonalData_modalWindow");
    const overlay = document.querySelector(".overlayFullScreen");

    popUp.classList.remove("activeEditor_PersonalData");
    overlay.remove();
  }

  //===============================================================

  //Exists Users
  const [users, setUsers] = useState([
    {
      id: 1,
      name: "User Example",
      mode: "Mode Example",
      mail: "mail@example.com",
    },
    {
      id: 2,
      name: "User Example1",
      mode: "Mode Example",
      mail: "mail@example.com",
    },
    {
      id: 3,
      name: "User Example2",
      mode: "Mode Example",
      mail: "mail@example.com",
    },
    {
      id: 4,
      name: "User Example3",
      mode: "Mode Example",
      mail: "mail@example.com",
    },
    {
      id: 5,
      name: "User Example4",
      mode: "Mode Example",
      mail: "mail@example.com",
    },
  ]);

  function editTable() {
    const table = document.querySelector(".usersTable");

    const th = table.querySelectorAll("th");
    th[2].style.borderRight = "0";

    const td = table.querySelectorAll("td");

    const tr = table.querySelectorAll("tr");
    tr[tr.length - 1].style.borderBottom = "0";

    for (let i = 0; i < td.length; i++) {
      if ((i + 1) % 3 === 0) {
        td[i].style.borderRight = "0";
      }
    }
  }

  useEffect(() => {
    editTable();
  }, []);

  const [selectedOption, setSelectedOption] = useState();

  let [options] = useState([
    {
      value: "importFromDB1",
      label: "importFromDB1",
    },
    {
      value: "importFromDB2",
      label: "importFromDB2",
    },
    {
      value: "importFromDB3",
      label: "importFromDB3",
    },
    {
      value: "importFromDB4",
      label: "importFromDB4",
    },
    {
      value: "importFromDB5",
      label: "importFromDB5",
    },
  ]);

  const SelectMode = () => {
    return (
      <Select
        name="serviceType"
        isMulti
        data-flag="0"
        value={selectedOption}
        onChange={(event) => handleChange_ServiceType(event)}
        options={options}
        placeholder="Выберите режим пользователя"
      />
    );
  };

  const handleChange_ServiceType = (selectedOption) => {
    setSelectedOption(selectedOption);
    choosedOption = selectedOption.value;
  };

  const [userName, setUserName] = useState("");


  function editCurrentUser(event) {
    setUserName("");
    const currentUser = event.currentTarget;

    const userInfo = [
      {
        userName: "",
        userMode: "",
        userMail: "",
      },
    ];

    const currentUserRow = currentUser.closest("tr");
    const mail = currentUserRow.getAttribute("data-mail");
    currentUserRow.classList.add("editing");

    userInfo[0].userName = currentUserRow.querySelector(".name").innerHTML;
    userInfo[0].userMail = mail;

    const popUp = document.querySelector(".editUser_modalWindow");
    popUp.classList.add("activeEditUser");

    setUserName(userInfo[0].userName);

  }

  function handleSaveChanges() {
    const popUp = document.querySelector(".editUser_modalWindow");
    const nameInput = popUp.querySelector(".userName");
    const currentUserRow = document.querySelector("tr.editing"); // Assuming there's a way to mark the current row being edited

    if (nameInput.value.length > 0) {
      currentUserRow.querySelector(".name").innerHTML = nameInput.value;
      popUp.classList.remove("activeEditUser");
      if (selectedOption && selectedOption.label) {
        currentUserRow.querySelector(".mode").innerHTML = selectedOption.label;
      }
    }

    currentUserRow.classList.remove("editing");
  }

  function handleDeleteUser() {
    const editingRow = document.querySelector("tr.editing");
    const userId = parseInt(editingRow.getAttribute("data-id"));
    const updatedUsers = users.filter((user) => user.id !== userId);
    setUsers(updatedUsers);
    const popUp = document.querySelector(".editUser_modalWindow");
    popUp.classList.remove("activeEditUser");
  }

  useEffect(() => {
    const saveBTN = document.querySelector(".editUser_modalWindow .save-btn");
    if (saveBTN) {
      saveBTN.addEventListener("click", handleSaveChanges);
    }

    return () => {
      if (saveBTN) {
        saveBTN.removeEventListener("click", handleSaveChanges);
      }
    };
  }, [selectedOption, userName]);

  function SaveNewUser() {
    const addNewUserEditor = document.querySelector(".addNewUserEditor");
    const name = addNewUserEditor.querySelector(".NewUser_name");
    const mail = addNewUserEditor.querySelector(".NewUser_mail");
    const password = addNewUserEditor.querySelector(".NewUser_password");

    let isValid = true;

    // Функция для установки красного цвета границы и возврата к исходному
    const highlightError = (element) => {
      const originalBorder = element.style.borderColor;
      element.style.borderColor = "#ff0000";
      setTimeout(() => {
        element.style.borderColor = originalBorder;
      }, 1000);
    };

    // Проверка полей на пустоту
    if (!name.value.trim()) {
      highlightError(name);
      isValid = false;
    }
    if (!mail.value.trim()) {
      highlightError(mail);
      isValid = false;
    }
    if (!password.value.trim()) {
      highlightError(password);
      isValid = false;
    }

    // Если все поля заполнены, продолжаем сохранение пользователя
    if (isValid) {
      const mode = selectedOption ? selectedOption.label : "Режим не выбран"; // Или другое дефолтное значение

      const newUser = {
        name: name.value,
        mode: mode,
        mail: mail.value,
      };

      setUsers([newUser, ...users]);

      addNewUserEditor.classList.remove("activeAddNewUserEditor");
      name.value = "";
      mail.value = "";
      password.value = "";
    }
  }

  function cancelNewUser() {
    const addNewUserEditor = document.querySelector(".addNewUserEditor");
    const name = addNewUserEditor.querySelector(".NewUser_name");
    const mail = addNewUserEditor.querySelector(".NewUser_mail");
    const password = addNewUserEditor.querySelector(".NewUser_password");

    addNewUserEditor.classList.remove("activeAddNewUserEditor");
    name.value = "";
    mail.value = "";
    password.value = "";
  }

  function addNewUser() {
    const addNewUserEditor = document.querySelector(".addNewUserEditor");
    addNewUserEditor.classList.add("activeAddNewUserEditor");
  }

  const cancelEditUser = () => {
    const editUserModal = document.querySelector(".editUser_modalWindow");
    editUserModal.classList.remove("activeEditUser");
    const currentUserRow = document.querySelector("tr.editing");
    if (currentUserRow) {
      currentUserRow.classList.remove("editing");
    }
  };

  return (
    <div className="changePersonalData_modalWindow">
      <div className="row">
        <h3>Настройки</h3>

        <button onClick={(event) => closeModalWindow(event)}>
          <img src={process.env.PUBLIC_URL + "/img/svg/close.svg"} alt="" />
        </button>
      </div>

      <div className="container">
        <div className="left">
          <button
            className="activeButton"
            value=".common"
            onClick={(event) => showCurrentSetting(event)}
          >
            <img src={process.env.PUBLIC_URL + "/img/svg/shest.svg"} alt="" />{" "}
            Общее
          </button>
          <button value=".users" onClick={(event) => showCurrentSetting(event)}>
            <img src={process.env.PUBLIC_URL + "/img/svg/user.svg"} alt="" />{" "}
            Пользователи
          </button>

          <button className="logout" onClick={(event) => logoutFromAcc(event)}>
            <img src={process.env.PUBLIC_URL + "/img/svg/logout.svg"} alt="" />
            Выйти
          </button>
        </div>

        <div className="right">
          <div className="common settings-block activeSettingsBlock">
            <div className="block">
              <p>Почта</p>
              <input
                type="mail"
                className="mail"
                disabled
                value="exampleMail"
              />
            </div>

            <div className="block">
              <p>Никнейм</p>
              <input
                type="text"
                className="NewName"
                required
                placeholder="Имя пользователя должно содержать от 6 символов"
              />
            </div>

            <div className="block">
              <p>Изменить пароль</p>
              <input type="text" value="" />
            </div>

            <button className="save-button" onClick={() => saveChanges()}>
              Сохранить
            </button>
          </div>

          <div className="users settings-block">
            <div className="block">
              <button className="addNewUser" onClick={() => addNewUser()}>
                Добавить нового пользователя
                <img src={process.env.PUBLIC_URL + "/img/svg/add.svg"} alt="" />
              </button>

              <div className="tableContainer">
                <table className="usersTable">
                  <tr>
                    <th>Пользователь</th>
                    <th>Режим</th>
                    <th>Редактировать</th>
                  </tr>

                  {users.map((user, index) => {
                    return (
                      <tr key={index} data-id={user.id}>
                        <td className="name">{user.name}</td>
                        <td className="mode">{user.mode}</td>
                        <td className="center">
                          <button onClick={(event) => editCurrentUser(event)}>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/svg/write.svg"
                              }
                              alt=""
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>

              <div className="editUser_modalWindow">
                <button className="mb-1" onClick={cancelEditUser}>
                  <img
                    src={process.env.PUBLIC_URL + "/img/svg/arrow-left.svg"}
                    alt=""
                  />
                </button>
                <div className="block">
                  <p>Имя пользователя</p>
                  <input
                    className="userName"
                    type="text"
                    value={userName}
                    onInput={(e) => setUserName(e.target.value)}
                  />
                </div>

                <div className="block">
                  <p>Изменить пароль</p>
                  <input type="password" value="" />
                </div>

                <SelectMode />

                <div className="row-flex">
                  <button onClick={handleDeleteUser} className="del-btn-user">
                    Удалить пользователя
                  </button>
                  <button className="save-btn">Сохранить</button>
                </div>
              </div>

              <div className="addNewUserEditor">
                <div className="block">
                  <p>Почта пользователя</p>

                  <input type="mail" className="NewUser_mail" />
                </div>

                <div className="block">
                  <p>Имя пользователя</p>
                  <input type="text" className="NewUser_name" />
                </div>

                <div className="block">
                  <p>Режим пользователя</p>
                  <SelectMode />
                </div>

                <div className="block">
                  <p>Пароль пользователя</p>
                  <input type="text" className="NewUser_password" />
                </div>

                <div className="block-row">
                  <button
                    className="cancelNewUser"
                    onClick={() => cancelNewUser()}
                  >
                    Отмена
                  </button>

                  <button className="saveNewUser" onClick={() => SaveNewUser()}>
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalData;
