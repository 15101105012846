import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import React from "react";
import "../login/login.css";
import "../../App.css";
import { RegAPI } from "../api";
import { useNavigate } from "react-router-dom";
import { LoginUser } from "../api";




function Form() {
  const [nameInput, SetNameInput] = useState("");
  const [passwordInput, SetPasswordInput] = useState("");
  const [disabled, setDisabled] = useState(1);
  const navigate = useNavigate();


  async function Registration(nameInput, passwordInput) {
    let okey = await RegAPI(nameInput, passwordInput)
    
    if (okey.data.registered === 'ok') {
      let userdata = await LoginUser(nameInput, passwordInput)
      localStorage.setItem('jwt', userdata)
      localStorage.setItem('username', nameInput)
      navigate("/user", { replace: true });
    }
    else {
      alert('Такой пользователь уже существует.')
    }
  }

  useEffect(() => {
    let submitButton = document.querySelector(".submitButton");
    if (nameInput !== "" && passwordInput !== "") {
      submitButton.classList.add("activeButton");
      setDisabled(0);
    } else {
      submitButton.classList.remove("activeButton");
      setDisabled(1);
    }
  }, [nameInput, passwordInput]);

  return (
    <div className="container">
      <div className="screen">
        <div className="screen__content">
          <form>
            <h3>Регистрация</h3>
            <input
              onInput={(event) => SetNameInput(event.target.value)}
              type="text"
              className="login"
              placeholder="Логин"
              name="username"
            />
            <input
              onInput={(event) => SetPasswordInput(event.target.value)}
              className="password"
              type="password"
              placeholder="Пароль"
              name="password"
            />
            <button className="submitButton"
              type="button"
              onClick={async() => await Registration(nameInput, passwordInput)}
              disabled={disabled}>
              Зарегистрироваться
            </button>

            <div className="row-inputs">
              <a href="/">Уже есть аккаунт?</a>
            </div>
          </form>

          <div className="social-login">
            <h3>Войти через:</h3>

            <div className="social-icons">
              <a href="https://web.telegram.org" className="telega">
                Telegram
              </a>
            </div>
          </div>
        </div>

        <div className="screen__background">
          <span className="screen__background__shape screen__background__shape4"></span>

          <span className="screen__background__shape screen__background__shape3"></span>

          <span className="screen__background__shape screen__background__shape2"></span>

          <span className="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
    </div>
  );
}
export default function Register() {
  useEffect(() => {
    document.body.classList.add("noscroll");
  }, []);
  return (
    <main className="login">
      <Helmet>
        <title>Register</title>
      </Helmet>
      <Form />;
    </main>
  );
}
