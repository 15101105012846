import "./App.css";
import "./css/reset.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/login/login.js";
import Register from "./pages/register/register.js";
import AdminPart from "./pages/admin/admin.js";
import UserPart from "./pages/user/user.js";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/admin" element={<AdminPart />} />
        <Route path="/user" element={<UserPart />} />
      </Routes>
    </Router>
  );
}

export default App;
