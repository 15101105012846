import { useEffect, useState } from "react";
import React from "react";
import "./login.css";
import "../../App.css";
// import UserPart from "../user/user";
import { Navigate, useNavigate } from "react-router-dom";
import { LoginUser } from "../api";

// Как сделать "Неверно введен пароль"?
// Как перенаправлять на UserPart?
// То же самое с регистрацией. Как перенаправлять на /login (/)

function Form() {
  const [nameInput, SetNameInput] = useState("");
  const [passwordInput, SetPasswordInput] = useState("");
  const [disabled, setDisabled] = useState(1);
  const navigate = useNavigate();

  async function GoToPanel(nameInput, passwordInput) {
    let userdata = await LoginUser(nameInput, passwordInput);
    if (userdata !== false){
      localStorage.setItem('jwt', userdata)
      localStorage.setItem('username', nameInput)
      navigate('user/', {'replace': true})
      
    }
    else {
      alert('Вы неверно ввели данные.')
    }
  };

  
  useEffect(() => {
    let submitButton = document.querySelector(".submitButton");
    if (nameInput !== "" && passwordInput !== "") {
      submitButton.classList.add("activeButton");
      setDisabled(0);
    } else {
      submitButton.classList.remove("activeButton");
      setDisabled(1);
    }
  }, [nameInput, passwordInput]);


  return (
    <div className="container">
      <div className="screen">
        <div className="screen__content">
          <form>
            <h3>Вход</h3>
            <input
              onInput={(event) => SetNameInput(event.target.value)}
              type="text"
              className="login"
              name="username"
              placeholder="Логин"
            />
            <input
              onInput={(event) => SetPasswordInput(event.target.value)}
              className="password"
              type="password"
              name="password"
              placeholder="Пароль"
            />
            <button
              className="submitButton"
              type="button"
              onClick={async() => await GoToPanel(nameInput, passwordInput)}
              disabled={disabled}
            >
              Войти
            </button>

            <div className="row-inputs">
              <a href="/register">Регистрация</a>
            </div>
          </form>

          <div className="social-login">
            <h3>Войти через</h3>

            <div className="social-icons">
              <a href="https://web.telegram.org" className="telega">
                Telegram
              </a>
            </div>
          </div>
        </div>

        <div className="screen__background">
          <span className="screen__background__shape screen__background__shape4"></span>

          <span className="screen__background__shape screen__background__shape3"></span>

          <span className="screen__background__shape screen__background__shape2"></span>

          <span className="screen__background__shape screen__background__shape1"></span>
        </div>
      </div>
    </div>
  );
}
export default function Login() {
  useEffect(() => {
    document.body.classList.add("noscroll");
  }, []);
  if (localStorage.getItem('username')) {
    return <Navigate to='/user' />
  }
  return (
    <main className="login">
      <Form />;
    </main>
  )};
