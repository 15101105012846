import React from "react"
import { DeleteChat } from "../pages/api";

function Aside({
  chats,
  setChats,
  onChatSelect,
  handleOpenNewChat,
  handleOpenUserSettings,
  activeAside,
  setActiveAside,
  userSettings,
  activeChat,
}) {
  async function deleteCurrentChat(event) {
    event.stopPropagation();
    let currentChatID = event.currentTarget.getAttribute("data-chat-id");
    await DeleteChat(currentChatID)
    const updatedChats = chats.filter((chat) => chat.ID !== currentChatID);
    setChats(updatedChats);
  }


  const handleOpenAside = () => {
    setActiveAside(!activeAside);
  };
  return (
    <aside className={activeAside ? "activeAside" : ""}>
      <div className="mobile burger">
        <button onClick={() => handleOpenAside()}></button>
        <button onClick={() => handleOpenAside()}></button>
      </div>
      <div className="top">
        <button onClick={handleOpenNewChat}>
          <div className="row">
            <img src={process.env.PUBLIC_URL + "/img/svg/logo.svg"} alt="" />
            Новый чат
          </div>
          <img
            className="write"
            src={process.env.PUBLIC_URL + "/img/svg/write.svg"}
            alt=""
          />
        </button>
      </div>

      <div className="container">
        {chats.map((chat) => (
          <button
            key={chat.ID}
            data-id={chat.ID}
            onClick={() => onChatSelect(chat.ID)}
            className={`chat-button ${activeChat === chat.ID ? "active" : ""}`}
          >
            {chat.Name}
            <div className="rowInputs">
              <button
                data-chat-id={chat.ID}
                onClick={(event) => deleteCurrentChat(event)}
                className="del-btn"
              >
                <img src={process.env.PUBLIC_URL + "/img/svg/del.svg"} alt="" />
              </button>
            </div>
          </button>
        ))}
      </div>

      <div className="settings">
        <button onClick={handleOpenUserSettings}>
          <img src={process.env.PUBLIC_URL + "/img/svg/user.svg"} alt="" />
          {userSettings[0].Name}
        </button>
        <a href="#">Перейти в Telegram</a>
      </div>
    </aside>
  );
}

export default Aside;
