import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import "../../css/reset.css";
import "./admin.css";
import PersonalData from "../../components/PersonalData.jsx";
import DB_modalWindow from "../../components/db.jsx";
import Statistic from "./statistic/statistic.jsx";
// import axios from "axios";
import { Assistants, NewAssistant, UploadFileapi, DeleteFileAPI, DeleteAssistant } from "../api.js";


function Models({
  setActiveModel,
  models,
  setModels,
  openStatistic,
  setOpenStatistc,
}) {
  function openPerosnalDate_modalWindow() {
    const Editor = document.querySelector(".changePersonalData_modalWindow");
    Editor.classList.add("activeEditor_PersonalData");

    const overlay = document.createElement("div");
    overlay.classList.add("overlayFullScreen");

    document.body.appendChild(overlay);

    const NewData = document.querySelector(".NewName");
    const AsideName = document.querySelector("button.changeData span");

    NewData.value = AsideName.innerHTML;
    setActiveAside(!activeAside);
  }

  function openDB_modalWindow() {
    const db_modalWindow = document.querySelector(".db_modalWindow");
    db_modalWindow.classList.add("activeModalWindow_db");

    const overlay = document.createElement("div");
    overlay.classList.add("overlayFullScreen");
    document.body.appendChild(overlay);
    setActiveAside(!activeAside);
  }
  function openCurrentModel(modelValue) {
    const selectedModel = models.find((model) => model.value === modelValue);
    setActiveModel(selectedModel);
    setActiveAside(!activeAside);

    const modelsContainer = document.querySelector(".models");
    modelsContainer.classList.remove("hidden");
    setOpenStatistc(0);
  }

  const handleOpenNewMode = () => {
    const modalWindow = document.querySelector(".newMode_modal");
    modalWindow.classList.add("active_newMode");
    const overlay = document.createElement("div");
    overlay.classList.add("overlayFullScreen");
    document.body.appendChild(overlay);
    setActiveAside(!activeAside);
  };

  const [activeAside, setActiveAside] = useState(false);
  const openBurger = () => {
    setActiveAside(!activeAside);
  };

  const openStatistics = () => {
    setOpenStatistc(1);
    setActiveAside(0);
    setActiveModel(null);

    const models = document.querySelector(".models");
    models.classList.add("hidden");
  };

  const UserName = localStorage.getItem('username')

  return (
    <aside className={activeAside ? "activeAside" : ""}>
      <div className="mobile burger">
        <button onClick={() => openBurger()}></button>
        <button onClick={() => openBurger()}></button>
      </div>
      <div className="top">
        <button onClick={handleOpenNewMode}>
          <div className="row">
            <img src={process.env.PUBLIC_URL + "/img/svg/logo.svg"} alt="" />
            Новый режим
          </div>
          <img
            className="write"
            src={process.env.PUBLIC_URL + "/img/svg/write.svg"}
            alt=""
          />
        </button>
      </div>
      <div className="container">
        {models.map((model, index) => (
          <button
            className={"chat" + " " + model.class}
            key={index}
            onClick={() => openCurrentModel(model.value)}
          >
            {model.name}
          </button>
        ))}
      </div>
      <div className="settings">
        <button onClick={openStatistics}>
          <img src={process.env.PUBLIC_URL + "/img/svg/stata.svg"} alt="" />
          Статистика
        </button>
        <button
          className="changeData"
          onClick={() => openPerosnalDate_modalWindow()}
        >
          <img src={process.env.PUBLIC_URL + "/img/svg/user.svg"} alt="" />
          <span>{UserName}</span>
        </button>
      </div>
    </aside>
  );
}

function ModelContainer({ setActiveModel, activeModel, models, setModels }) {
  const [tempModelName, setTempModelName] = useState(
    activeModel ? activeModel.name : ""
  );
  const [tempModelPrompt, setTempModelPrompt] = useState(
    activeModel ? activeModel.prompt : ""
  );
  const [activeDeleteFileIndex, setActiveDeleteFileIndex] = useState(null);

  function createDeleteBlock(index, isNewFile = false) {
    if (isNewFile) {
      setActiveDeleteFileIndex(index);
    } else {
      setActiveDeleteFileIndex(index);
    }
  }

  function deleteCurrentFile(modelIndex, fileIndex, modelName) {
    const updatedModels = [...models];
    if (!updatedModels[modelIndex]) {
      console.error("Модель не найдена");
      return;
    }
    DeleteFileAPI(modelName, fileIndex);
    const modelToUpdate = updatedModels[modelIndex];

    if (!modelToUpdate.files) {
      console.error("Файлы для удаления не найдены");
      return;
    }

    const updatedFiles = modelToUpdate.files.filter(
      (_, index) => index !== fileIndex
    );

    modelToUpdate.files = updatedFiles;

    setModels(updatedModels);
    setActiveDeleteFileIndex(null);
  }
  function cancelDeletingCurrentFile(event) {
    event.stopPropagation();
    setActiveDeleteFileIndex(null);
  }

  const [files, setFiles] = useState(activeModel ? activeModel.files : []);

  useEffect(() => {
    if (activeModel) {
      setTempModelName(activeModel.name);
      setTempModelPrompt(activeModel.prompt);
    }
  }, [activeModel]);

  const handleSaveChanges = () => {
    const updatedModels = models.map((model) => {
      if (model.value === activeModel.value) {
        return { ...model, name: tempModelName, prompt: tempModelPrompt };
      }
      return model;
    });

    setModels(updatedModels, () => {
      const updatedActiveModel = updatedModels.find(
        (model) => model.value === activeModel.value
      );
      setActiveModel(updatedActiveModel);
    });
  };

  const deleteCurrentMode = () => {
    DeleteAssistant(activeModel.name)
    const updatedModels = models.filter(
      (model) => model.value !== activeModel.value
    );

    setModels(updatedModels);
    setActiveModel(null);
  };
  function createDeleteBlock(index) {
    setActiveDeleteFileIndex(index);
  }

  useEffect(() => {
    if (activeModel) {
      const updatedActiveModel = models.find(
        (model) => model.value === activeModel.value
      );
      setActiveModel(updatedActiveModel);
    }
  }, [models, setActiveModel, activeModel]);


  const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('AssistantName', activeModel.name)
    formData.append('file', file); // 'file' это ключ, по которому на сервере будет получен файл
    let uploaded = UploadFileapi(formData);
    alert(uploaded);
    if (uploaded === 'Not uploaded'){
      return 'error';
    }
  };



  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    const upload = uploadFile(file);
    if (!file || upload === 'error') {
      return;
    }
    
    const newFile = {
      fileName: file.name,
      fileWeight: `${(file.size / 1024 / 1024).toFixed(2)}Мб`,
      dateUpload: new Date().toLocaleDateString(),
    };


    const updatedModels = models.map((model) => {
      if (model.value === activeModel.value) {
        const updatedFiles = [...model.files, newFile];
        return {
          ...model,
          files: updatedFiles,
        };
      }
      return model;
    });

    setModels(updatedModels);

    event.target.value = "";
  };

  const triggerFileInputClick = () => {
    document.getElementById("fileInput").click();
  };

  if (!activeModel) {
    return (
      <div className="models">
        <h2>Выберите режим</h2>
      </div>
    );
  }

  return (
    <div className={"models"}>
      <div className={"currentModel " + activeModel.class + " activeModel"}>
        <div className="container">
          <div className="left">
            <div className="block">
              <p>Название режима</p>
              <input
                type="text"
                value={tempModelName}
                onChange={(e) => setTempModelName(e.target.value)}
              />
            </div>

            <div className="block">
              <p>Промпт</p>
              <textarea
                value={tempModelPrompt}
                onChange={(e) => setTempModelPrompt(e.target.value)}
                cols="30"
                rows="10"
              ></textarea>
            </div>

            <div className="block">
              <div className="row">
                <button className="del" onClick={deleteCurrentMode}>
                  Удалить режим
                </button>

                <button className="save" onClick={handleSaveChanges}>
                  Сохранить
                </button>
              </div>
            </div>
          </div>

          <div className="right">
            <div className="block">
              <p>Файлы режима</p>
            </div>
            <div className="files">
              <div className="files-container">
                {activeModel &&
                  activeModel.files.map((file, index) => {
                    return (
                      <div
                        className="file"
                        onClick={() => createDeleteBlock(index)}
                        key={index}
                      >
                        <div className="row-file">
                          <img
                            src={process.env.PUBLIC_URL + "/img/svg/file.svg"}
                            alt=""
                          />

                          <p>{file.fileName}</p>
                        </div>

                        <div className="row-file">
                          <p>{file.fileWeight}</p>
                          <p>{file.dateUpload}</p>
                        </div>

                        {activeDeleteFileIndex === index && (
                          <div className="deleteSection">
                            <button
                              className="deleteFileButton"
                              onClick={(event) => {
                                event.stopPropagation();
                                const modelIndex = models.findIndex(
                                  (model) => model.value === activeModel.value
                                );
                                deleteCurrentFile(modelIndex, index, activeModel.name);
                              }}
                            >
                              Удалить файл
                            </button>
                            <button
                              className="cancelDeleteFile"
                              onClick={(event) =>
                                cancelDeletingCurrentFile(event)
                              }
                            >
                              Отмена
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileSelect}
            />
            <button className="addFile" onClick={triggerFileInputClick}>
              Добавить файл
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function NewMode({ setNewMode, addNewMode, newMode, files, setFiles }) {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMode((prev) => ({ ...prev, [name]: value }));
  };
  const triggerFileInputClick = () => {
    document.getElementById("fileInputNew").click();
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const updatedFiles = files.concat(
      selectedFiles.map((file) => ({
        fileName: file.name,
        fileWeight: (file.size / (1024 * 1024)).toFixed(2) + "ГБ", // Correct calculation here
      }))
    );
    setFiles(updatedFiles);
    setNewMode((prev) => ({ ...prev, files: updatedFiles }));
  };

  const handleSave = () => {
    NewAssistant(newMode.name, newMode.prompt);
    addNewMode();
  };

  function closeNewMode() {
    const modalWindow = document.querySelector(".newMode_modal");
    modalWindow.classList.remove("active_newMode");
    const overlay = document.querySelector(".overlayFullScreen");
    if (overlay) overlay.remove();

    setNewMode({ name: "", prompt: "", files: [] });
    setFiles([]);
  }

  return (
    <div className="newMode_modal">
      <div className="row">
        <h3>Новый режим</h3>
        <button onClick={() => closeNewMode()}>
          <img src={process.env.PUBLIC_URL + "/img/svg/close.svg"} alt="" />
        </button>
      </div>
      <div className="block">
        <p>Название</p>
        <input
          type="text"
          className="name"
          name="name"
          value={newMode.name}
          onChange={handleInputChange}
        />
      </div>

      <div className="block">
        <p>Промпт</p>
        <textarea
          name="prompt"
          cols="30"
          rows="10"
          value={newMode.prompt}
          onChange={handleInputChange}
        ></textarea>
      </div>

      <div className="files-container">
        {files.map((file, index) => {
          return (
            <div className="file" key={index}>
              <div className="row-file">
                <img
                  src={process.env.PUBLIC_URL + "/img/svg/file.svg"}
                  alt=""
                />
                <p>{file.fileName}</p>
              </div>

              <div className="row-file">
                <p>{file.fileWeight}</p>
                <p>{file.dateUpload}</p>
              </div>
            </div>
          );
        })}
      </div>

      <input
        type="file"
        id="fileInputNew"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <button className="addFile" onClick={triggerFileInputClick}>
        Добавить файл
      </button>

      <div className="row-buttons">
        <button className="cancelNewMode" onClick={closeNewMode}>
          Отмена
        </button>
        <button className="addNewMode" onClick={handleSave}>
          Сохранить
        </button>
      </div>
    </div>
  );
}

function AdminPart() {
  const [activeModel, setActiveModel] = useState(null);

  const [models, setModels] = useState([]);
  useEffect(() => {
    async function fetchData() {
        const data = await Assistants();
        setModels(data);
    }
    fetchData();
}, []);

  const [newMode, setNewMode] = useState({ name: "", prompt: "", files: [] });
  const [files, setFiles] = useState([]);

  const addNewMode = () => {
    const modalWindow = document.querySelector(".newMode_modal");
    const nameInput = modalWindow.querySelector(".name");
    if (newMode.name.length >= 6) {
      setModels([
        ...models,
        { ...newMode, value: `model${models.length + 1}` },
      ]);
      setNewMode({ name: "", prompt: "", files: [] });
      modalWindow.classList.remove("active_newMode");
      const overlay = document.querySelector(".overlayFullScreen");
      overlay.remove();
      setNewMode({ name: "", prompt: "", files: [] });
      setFiles([]);
    } else {
      nameInput.style.borderColor = "#ff0000";
      setTimeout(() => {
        nameInput.style.borderColor = "#00000026";
      }, 2000);
    }
  };

  ////////////////////////////////////////////////////////////////////////////////

  //STATISTIC PART

  const [openStatistic, setOpenStatistc] = useState(0);

  useEffect(() => {
    document.body.classList.add("panel");
  }, []);

  if (localStorage.getItem('username') !== 'admin') {
    return (
      <>
    <main className="noaccess">
        <div className="noaccess-div">
        <h1 className='noaccess-text'>
          У вас нет доступа.
        </h1>
        <a className="a-back-login" href="/">Назад</a>
        </div>
      </main>
      </>
    )
  }

  return (
    <>
      <main className="admin">
        <Helmet>
          <title>Админ-панель</title>
        </Helmet>
        <Models
          setActiveModel={setActiveModel}
          models={models}
          setModels={setModels}
          openStatistic={openStatistic}
          setOpenStatistc={setOpenStatistc}
        />
        <ModelContainer
          setActiveModel={setActiveModel}
          activeModel={activeModel}
          models={models}
          setModels={setModels}
        />
        <Statistic openStatistic={openStatistic} />
      </main>
      <PersonalData />
      <DB_modalWindow />
      <NewMode
        newMode={newMode}
        setNewMode={setNewMode}
        addNewMode={addNewMode}
        files={files}
        setFiles={setFiles}
      />
    </>
  );
}

export default AdminPart;