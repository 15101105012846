import axios from "axios";

const ADMIN_API = 'http://195.54.178.243:24012/admin/api/v1';
const USER_API = 'http://195.54.178.243:24012/user/api/v1';


// Admin Part
// export let Assistants = await axios.get(`${ADMIN_API}/getAssistants`)

export async function Assistants() {
    const response = await axios.get(`${ADMIN_API}/getAssistants`)
    return response.data
}


export async function NewAssistant(name, prompt) {
    axios.post(`${ADMIN_API}/newAssistant`, {'name': name, 'prompt': prompt})
};

export async function DeleteAssistant(name) {
    axios.post(`${ADMIN_API}/deleteAssistant`, {'name': name, 'prompt': 'None'})
};

export function UploadFileapi(formData) {
    fetch(`${ADMIN_API}/uploadFile`, {
        method: 'POST',
        body: formData
      }).then(response => response.json()) // Преобразовываем ответ в JSON.
        .then(data => {
          if (data.data === 'Not uploaded') { // Проверяем поле data.message в полученном объекте JSON.
            alert('Файл данного формата не поддерживается');
            return 'Not uploaded'
          }
          else {
            alert("Файл успешно загружен!")
          }
        })
};

export function DeleteFileAPI(modelName, fileIndex) {
    axios.post(`${ADMIN_API}/deleteFile`, {"assistant_name": modelName, "index": fileIndex});
};

export async function GetStatistic() {
    let response = await axios.get(`${ADMIN_API}/getStatistic`)
    return response
};

export async function GetMostUsed() {
    let response = await axios.get(`${ADMIN_API}/getMostUsed`)
    return response
};

// User Part

export async function RegAPI(nameInput, passwordInput) {
    let okey = await axios.post('http://195.54.178.243:24012/auth/register', `username=${nameInput}&password=${passwordInput}`)
    return okey
};


export async function LoginUser(nameInput, passwordInput) {
    try {
        const response = await axios.post('http://195.54.178.243:24012/auth/login', `username=${nameInput}&password=${passwordInput}`);
        console.log(response);
        console.log(response.status);
        console.log(response.data.access_token);
        if (response.status === 200) {
            return response.data.access_token; // Возвращаем токен доступа непосредственно из функции
        } else {
            return false; // Возвращаем false, если статус ответа не 200
        }
    } catch (error) {
        return false; // Также возвращаем false в случае ошибки
    }
};

export async function ChangePass(username, newpass) {
    await axios.post('http://195.54.178.243:24012/auth/setNewPass', {'username': username, 'newpass': newpass})
}

// export const UserAssistants = await axios.get(`${USER_API}/getAssistantsList`);

export async function UserAssistants() {
    const response = await axios.get(`${USER_API}/getAssistantsList`);
    return response.data 
}

export async function UserChats (UserInfo) {
    const uchats = await axios.post(`${USER_API}/getUserChats`, {'username': UserInfo})
    return uchats
};

export async function DeleteChat (currentChatID) {
    await axios.post(`${USER_API}/deleteChat`, {'chat_id': currentChatID})
};

export async function botAnswer(messageText, chatID, assistant) {
    const username = localStorage.getItem('username')
    try{
    let response = await axios.post(`${USER_API}/sendMessage`, {"username": username, "chat_thread": chatID, "message": messageText, "assistant": assistant})
    return {message: response.data.message, type: "bot"}
    }
    catch (e){
        alert('Ассистент удален. Перезагрузите страницу')
        return {message: 'Ошибка', type: 'bot'}
    }
};

export async function getThreadID(modename, username, newChatName) {
    let thread_id = await axios.post(`${USER_API}/createChat`, {'assistant_name': modename, 'username': username, 'chat_name': newChatName})
    
    return thread_id
};

