import React, { useEffect, useState } from "react";

function DB_modalWindow() {
  function closeWindow() {
    const db_modalWindow = document.querySelector(".db_modalWindow");
    db_modalWindow.classList.remove("activeModalWindow_db");
    const addNewDB = document.querySelector(".addNewDB");
    addNewDB.classList.remove("activeNewDB");

    const overlay = document.querySelector(".overlayFullScreen");
    if (overlay) overlay.remove();

    setDataBases([...dataBases]);
    setFiles([...files]);
    setNewFilesData([...newFiles]);

    const dbNameInput = document.querySelector(".editor_newDBInput");
    if (dbNameInput) dbNameInput.value = "";
    const fileInput = fileInputRef.current;
    if (fileInput) fileInput.value = "";
    const newFileInput = newFileInputRef.current;
    if (newFileInput) newFileInput.value = "";
  }

  const [rangeValue, setRangeValue] = useState(0);

  const handleRangeChange = (event) => {
    setRangeValue(event.target.value);
    event.target.classList.toggle("red");
  };

  const [dataBases, setDataBases] = useState([
    {
      BaseName: "ExampleBase1",
      BaseActive: 0,
      id: 1,
    },
    {
      BaseName: "ExampleBase2",
      BaseActive: 1,
      id: 2,
    },
    {
      BaseName: "ExampleBase3",
      BaseActive: 0,
      id: 3,
    },
  ]);

  function chooseActiveDB() {
    const table = document.querySelector(".db_table");
    const TR = document.querySelectorAll(".db_table tr");
    let topRow = table.querySelector(".topRow");

    TR.forEach((row) => {
      const Status = row.getAttribute("data-status");

      if (Status === "1") {
        row.classList.add("activeDB");
        topRow.insertAdjacentElement("afterend", row);
      }
    });
  }

  useEffect(() => {
    editTable_db();
    chooseActiveDB();
  }, []);

  function editTable_db() {
    const table = document.querySelector(".tableContainer .db_table");

    const th = table.querySelectorAll("th");
    th[1].style.borderRight = "0";

    const td = table.querySelectorAll("td");

    for (let i = 0; i < td.length; i++) {
      if ((i + 1) % 2 === 0) {
        td[i].style.borderRight = "0";
      }
    }
  }

  function editDB(event) {
    const currentButton = event.currentTarget;
    const currentDB = currentButton.closest("tr");
    const dbId = parseInt(currentDB.getAttribute("data-id"));
    const dbStatus = currentDB.getAttribute("data-status");

    const currentDB_name = currentDB.querySelector(".DB_name");

    const db_editor = document.querySelector(".DB_editor");
    let range = db_editor.querySelector(".range");

    switch (parseInt(dbStatus)) {
      case 1:
        range.value = 1;
        range.classList.remove("red");
        break;
      case 0:
        range.value = 0;
        range.classList.add("red");
        break;

      default:
        range.value = 0;
        range.classList.add("red");
        break;
    }

    let editor_nameInput = db_editor.querySelector(".editor_nameInput");
    editor_nameInput.value = currentDB_name.textContent;
    db_editor.classList.add("active_dbEditor");

    const saveButton = db_editor.querySelector(".save-db");
    const deleteButton = db_editor.querySelector(".delete-db");
    const proofButton = db_editor.querySelector(".delete-db-proof");
    const cancelButton = db_editor.querySelector(".cancel");

    saveButton.removeEventListener("click", save);
    deleteButton.removeEventListener("click", deleteDB);
    proofButton.removeEventListener("click", deleteProof);
    cancelButton.removeEventListener("click", cancel);

    let proof = document.querySelector(".proof");

    function save() {
      const updatedDatabases = dataBases.map((db) => {
        if (db.id === dbId) {
          return {
            ...db,
            BaseName: editor_nameInput.value,
            BaseActive: parseInt(range.value),
          };
        } else {
          return {
            ...db,
            BaseActive: 0,
          };
        }
      });

      setDataBases(updatedDatabases);
      db_editor.classList.remove("active_dbEditor");
      chooseActiveDB();
      editTable_db();
      cancel();
    }

    function deleteDB() {
      const updatedDatabases = dataBases.filter((db) => db.id !== dbId);
      setDataBases(updatedDatabases);
      db_editor.classList.remove("active_dbEditor");
      chooseActiveDB();
      editTable_db();

      cancel();
    }

    function deleteProof() {
      proof.classList.add("activeProof");
    }

    function cancel() {
      proof.classList.remove("activeProof");
    }

    saveButton.addEventListener("click", save);
    deleteButton.addEventListener("click", deleteDB);
    proofButton.addEventListener("click", deleteProof);
    cancelButton.addEventListener("click", cancel);
  }

  const Files = [
    {
      fileName: "ExampleFileName",
      fileWeight: "48Kb",
      dateUpload: "16.04.2024",
    },
    {
      fileName: "ExampleFileName1",
      fileWeight: "49Kb",
      dateUpload: "17.04.2024",
    },
    {
      fileName: "ExampleFileName2",
      fileWeight: "49Kb",
      dateUpload: "17.04.2024",
    },
    {
      fileName: "ExampleFileName3",
      fileWeight: "49Kb",
      dateUpload: "17.04.2024",
    },
  ];

  const [files, setFiles] = useState(Files);

  const fileInputRef = React.createRef();

  function addFile() {
    fileInputRef.current.click();
  }

  function handleFileChange(event) {
    const file = event.target.files[0];
    if (file) {
      const newFile = {
        fileName: file.name,
        fileWeight: `${(file.size / (1024 * 1024)).toFixed(2)}Мб`,
        dateUpload: new Date().toLocaleDateString(),
      };
      setFiles([newFile, ...files]);
    }
  }

  const newFileInputRef = React.createRef();

  const newFiles = [
    {
      fileName: "ExampleNewFileName",
      fileWeight: "48Kb",
      dateUpload: "16.04.2024",
    },
    {
      fileName: "ExampleNewFileName1",
      fileWeight: "49Kb",
      dateUpload: "17.04.2024",
    },
    {
      fileName: "ExampleNewFileName2",
      fileWeight: "49Kb",
      dateUpload: "17.04.2024",
    },
    {
      fileName: "ExampleNewFileName3",
      fileWeight: "49Kb",
      dateUpload: "17.04.2024",
    },
  ];

  const [newFilesData, setNewFilesData] = useState(newFiles);

  function handleNewFileChange(event) {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      const newFile = {
        fileName: file.name,
        fileWeight: `${(file.size / (1024 * 1024)).toFixed(2)}Мб`,
        dateUpload: new Date().toLocaleDateString(),
      };
      setNewFilesData([newFile, ...newFilesData]);
    }
  }

  function addNewDataBase() {
    const addNewDB_modal = document.querySelector(".addNewDB");
    addNewDB_modal.classList.add("activeNewDB");
    const dbNameInput = addNewDB_modal.querySelector(".editor_newDBInput");
    const saveButton = addNewDB_modal.querySelector(".save-createDB");
    const cancelButton = addNewDB_modal.querySelector(".cancel-createDB");

    saveButton.removeEventListener("click", saveDB);

    function cancel() {
      addNewDB_modal.classList.remove("activeNewDB");
      dbNameInput.value = "";
    }
    cancelButton.addEventListener("click", cancel);
    function saveDB() {
      if (dbNameInput.value.length >= 6) {
        console.log("saveDB");

        const newDB = {
          BaseName: dbNameInput.value,
          BaseActive: 0,
          id: dataBases.length + 1,
        };

        setDataBases([...dataBases, newDB]);

        addNewDB_modal.classList.remove("activeNewDB");
        dbNameInput.value = "";
      }
    }
    saveButton.addEventListener("click", saveDB);

    saveButton.addEventListener("click", saveDB);
  }

  const [activeDeleteFileIndex, setActiveDeleteFileIndex] = useState(null);
  const [activeDeleteNewFileIndex, setActiveDeleteNewFileIndex] =
    useState(null);
  function createDeleteBlock(index, isNewFile = false) {
    if (isNewFile) {
      setActiveDeleteNewFileIndex(index);
    } else {
      setActiveDeleteFileIndex(index);
    }
  }

  function deleteCurrentFile(event, index) {
    event.stopPropagation();
    setFiles(files.filter((_, fileIndex) => fileIndex !== index));
    setActiveDeleteFileIndex(null);
  }

  function cancelDeletingCurrentFile(event) {
    event.stopPropagation();
    setActiveDeleteFileIndex(null);
  }

  function deleteCurrentNewFile(event, index) {
    event.stopPropagation();
    setNewFilesData(newFilesData.filter((_, fileIndex) => fileIndex !== index));
    setActiveDeleteNewFileIndex(null);
  }

  function cancelDeletingCurrentNewFile(event) {
    event.stopPropagation();
    setActiveDeleteNewFileIndex(null);
  }

  useEffect(() => {
    console.log(newFilesData);
  }, [newFilesData]);

  useEffect(() => {
    editTable_db();
    chooseActiveDB();
  }, [dataBases]);

  const cancelEditDB = () => {
    const dbEditor = document.querySelector(".DB_editor");
    if (dbEditor) {
      dbEditor.classList.remove("active_dbEditor");
    }

    const editorNameInput = document.querySelector(".editor_nameInput");
    if (editorNameInput) {
      editorNameInput.value = "";
    }

    setRangeValue(0);
  };
  return (
    <>
      <div className="db_modalWindow">
        <div className="row">
          <h3>Базы данных</h3>

          <button onClick={() => closeWindow()}>
            <img src={process.env.PUBLIC_URL + "/img/svg/close.svg"} alt="" />
          </button>
        </div>

        <div className="container">
          <div className="left">
            <button className="activeButton">
              <img
                src={process.env.PUBLIC_URL + "/img/svg/database.svg"}
                alt=""
              />
              Управление базами
            </button>
          </div>

          <div className="right">
            <button className="addNewDB-btn" onClick={() => addNewDataBase()}>
              Добавить базу данных
              <img src={process.env.PUBLIC_URL + "/img/svg/add.svg"} alt="" />
            </button>

            <div className="tableContainer">
              <table className="db_table">
                <tr className="topRow">
                  <th>База данных</th>
                  <th>Редактировать</th>
                </tr>

                {dataBases.map((dataBase, index) => {
                  return (
                    <tr
                      key={dataBase.id}
                      data-id={dataBase.id}
                      data-status={dataBase.BaseActive}
                      className={dataBase.BaseActive === 1 ? "activeDB" : ""}
                    >
                      <td className="DB_name">{dataBase.BaseName}</td>
                      <td className="center">
                        <button onClick={(event) => editDB(event)}>
                          <img
                            src={process.env.PUBLIC_URL + "/img/svg/write.svg"}
                            alt=""
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>

            <div className="DB_editor">
              <button className="mb-2" onClick={cancelEditDB}>
                <img
                  src={process.env.PUBLIC_URL + "/img/svg/arrow-left.svg"}
                  alt=""
                />
              </button>
              <div className="block">
                <p>Название</p>
                <input type="text" className="editor_nameInput" />
              </div>

              <div className="block">
                <div className="row-flex">
                  <p>Статус: </p>
                  <input
                    type="range"
                    min="0"
                    max="1"
                    value={rangeValue}
                    onChange={handleRangeChange}
                    className="range red"
                  />
                </div>
              </div>

              <div className="block">
                <p>Файлы</p>

                <div className="files-container">
                  {files.map((file, index) => {
                    return (
                      <div
                        className="file"
                        key={index}
                        onClick={() => createDeleteBlock(index)}
                      >
                        <div className="row-file">
                          <img
                            src={process.env.PUBLIC_URL + "/img/svg/file.svg"}
                            alt=""
                          />
                          <p className="FileName">{file.fileName}</p>
                        </div>
                        <div className="row-file nMagin">
                          <p className="weight">{file.fileWeight}</p>
                          <p className="date">{file.dateUpload}</p>
                        </div>
                        {activeDeleteFileIndex === index && (
                          <div className="deleteSection">
                            <button
                              className="deleteFileButton"
                              onClick={(event) =>
                                deleteCurrentFile(event, index)
                              }
                            >
                              Удалить файл
                            </button>
                            <button
                              className="cancelDeleteFile"
                              onClick={(event) =>
                                cancelDeletingCurrentFile(event)
                              }
                            >
                              Отмена
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <button className="addFile" onClick={addFile}>
                  Добавить файл
                </button>
              </div>

              <div className="row-flex buttons">
                <button className="delete-db-proof">Удалить</button>
                <button className="save-db">Сохранить</button>
                <div className="proof">
                  <input type="text" placeholder="Введите пароль" />
                  <div className="column">
                    <button className="cancel">Отмена</button>
                    <button className="delete-db">Удалить</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="addNewDB">
              <div className="block">
                <p>Название</p>
                <input type="text" className="editor_newDBInput" />
              </div>

              <div className="block">
                <p>
                  Промпт (Текст, который нейросеть будет использовать в качестве
                  запроса)
                </p>

                <textarea cols="30" rows="10"></textarea>
              </div>

              <div className="block">
                <p>Файлы</p>

                <div className="files-containerr">
                  {newFilesData.map((file, index) => (
                    <React.Fragment key={index}>
                      <div
                        className="file"
                        onClick={(event) => createDeleteBlock(index, true)}
                      >
                        <div className="row-file">
                          <img
                            src={process.env.PUBLIC_URL + "/img/svg/file.svg"}
                            alt=""
                          />
                          <p className="FileName">{file.fileName}</p>
                        </div>
                        <div className="row-file nMagin">
                          <p className="weight">{file.fileWeight}</p>
                          <p className="date">{file.dateUpload}</p>
                        </div>
                        {activeDeleteNewFileIndex === index && (
                          <div className="deleteSection">
                            <button
                              className="deleteFileButton"
                              onClick={(event) =>
                                deleteCurrentNewFile(event, index)
                              }
                            >
                              Удалить файл
                            </button>
                            <button
                              className="cancelDeleteFile"
                              onClick={(event) =>
                                cancelDeletingCurrentNewFile(event)
                              }
                            >
                              Отмена
                            </button>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </div>

                <input
                  type="file"
                  ref={newFileInputRef}
                  style={{ display: "none" }}
                  onChange={handleNewFileChange}
                />
                <button
                  className="addFile"
                  onClick={() => newFileInputRef.current.click()}
                >
                  Добавить файл
                </button>
              </div>

              <div className="block-row">
                <button className="cancel-createDB">Отмена</button>
                <button className="save-createDB">Сохранить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DB_modalWindow;
