import { useEffect, useState } from "react";
import React from "react";
import Select from "react-select";
import "../../../css/reset.css";
import "./statistic.css";
import { GetStatistic, GetMostUsed } from "../../api";

function CommonStata({ commonStats }) {
  if (!commonStats || commonStats.length === 0) return null;

  const data = commonStats[0];
  return (
    <div className="common-stata default-48block">
      <h3>Общая статистика</h3>
      {Object.keys(data).map((key, index) => {
        const item = data[key];
        return (
          <div className="row" key={index}>
            <p className="bold">{item.text}</p>
            <p className="tochka">
              ....................................................................................................................................................................................................................................................................................................................................................................................
            </p>
            <p className="value">{item.count}</p>
          </div>
        );
      })}
    </div>
  );
}

function OftenStata({ oftenUserData }) {
  const data = oftenUserData[0];
  if (!data) {
    const data = {}
    return (
      <div className="often-stata default-48block">
        <h3>Часто используемые</h3>
        {Object.keys(data).map((key, index) => {
          const item = data[key];
          return (
            <div className="row" key={index}>
              <p className="bold">{item.text}</p>
              <p className="tochka">
                ....................................................................................................................................................................................................................................................................................................................................................................................
              </p>
              <p className="value">{item.value}</p>
            </div>
          );
        })}
      </div>
    );}
  return (
    <div className="often-stata default-48block">
      <h3>Часто используемые</h3>
      {Object.keys(data).map((key, index) => {
        const item = data[key];
        return (
          <div className="row" key={index}>
            <p className="bold">{item.text}</p>
            <p className="tochka">
              ....................................................................................................................................................................................................................................................................................................................................................................................
            </p>
            <p className="value">{item.value}</p>
          </div>
        );
      })}
    </div>
  );
}

function AllStata({ usersData, setCommonStats, commonStats }) {
  const optionsDuration = [
    { value: "all", label: "Все время" },
    { value: "day", label: "День" },
    { value: "week", label: "Неделя" },
    { value: "month", label: "Месяц" },
  ];

  const [selectedOption, setSelectedOption] = useState(optionsDuration[0]);
  const [filteredUsersData, setFilteredUsersData] = useState(usersData);
  const handleChangeDurationSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleShowStatistics = () => {
    const currentDate = new Date();
    let filteredData = [];

    switch (selectedOption.value) {
      case "day":
        filteredData = usersData.filter((user) => {
          const userDate = new Date(user.time.split(".").reverse().join("-"));
          return userDate.toDateString() === currentDate.toDateString();
        });
        break;
      case "week":
        const oneWeekAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 7
        );
        filteredData = usersData.filter((user) => {
          const userDate = new Date(user.time.split(".").reverse().join("-"));
          return userDate >= oneWeekAgo && userDate <= currentDate;
        });
        break;
      case "month":
        const oneMonthAgo = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          currentDate.getDate()
        );
        filteredData = usersData.filter((user) => {
          const userDate = new Date(user.time.split(".").reverse().join("-"));
          return userDate >= oneMonthAgo && userDate <= currentDate;
        });
        break;
      case "all":
      default:
        filteredData = [...usersData];
    }

    setFilteredUsersData(filteredData);

    const aggregatedStats = {
      Users: {
        text: "Пользователей",
        count: new Set(filteredData.map((user) => user.username)).size, // Подсчет уникальных пользователей
      },
      Tokens: {
        text: "Токенов",
        count: filteredData.reduce((acc, user) => acc + user.tokens, 0), // Суммирование токенов
      },
      Requests: {
        text: "Запросов",
        count: filteredData.reduce((acc, user) => acc + user.requests, 0), // Суммирование запросов
      },
      Modes: {
        text: "Режимов",
        count: new Set(filteredData.map((user) => user.assistant)).size, // Подсчет уникальных режимов
      },
      Spend: {
        text: "Затрат",
        count: filteredData.reduce(
          (acc, user) => acc + parseFloat(user.spent),
          0
        ),
      },
    };

    setCommonStats([aggregatedStats]);
  };

  function pagination(source) {
    const cards = document.querySelectorAll(source + " tr");
    const PaginationContainer = document.querySelector(".pagination");

    function showPage(pageNumber) {
      cards.forEach((card, index) => {
        if (index >= (pageNumber - 1) * 6 && index < pageNumber * 6) {
          card.style.display = "table-row";
        } else {
          card.style.display = "none";
        }
      });
    }
    function createPaginationLinks() {
      while (PaginationContainer.firstChild) {
        PaginationContainer.removeChild(PaginationContainer.firstChild);
      }

      const pageCount = Math.ceil(cards.length / 6);
      for (let i = 1; i <= pageCount; i++) {
        const link = document.createElement("a");
        link.href = "#";
        link.textContent = i;
        link.classList.add("linkk");
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const PaginationLinks = document.querySelectorAll(".linkk");
          PaginationLinks.forEach((link) => {
            link.classList.remove("active");
          });
          link.classList.add("active");
          showPage(i);
        });

        PaginationContainer.appendChild(link);
      }
    }

    createPaginationLinks();
    showPage(1);
    let first_link = document.querySelector(".linkk");
    first_link.classList.add("active");
  }

  useEffect(() => {
    pagination(".allStata table");
  }, [filteredUsersData]);

  return (
    <div className="allStata">
      <h3>Вся статистика</h3>
      <div className="selectRow">
        <Select
          name="serviceType"
          value={selectedOption}
          onChange={handleChangeDurationSelect}
          options={optionsDuration}
          placeholder="Выберите период за который нужно показать статистику"
        />
        <button className="submitButton" onClick={handleShowStatistics}>
          Показать статистику
        </button>
      </div>

      <div className="tableContainer">
        <table>
          <tr>
            <th>Пользователь</th>
            <th>Режим</th>
            <th>Использовано токенов</th>
            <th>Количество запросов</th>
            <th>Потрачено</th>
          </tr>
          {filteredUsersData.map((user) => (
            <tr key={user.id}>
              <td>{user.username}</td>
              <td>{user.assistant}</td>
              <td>{user.tokens}</td>
              <td>{user.requests}</td>
              <td>{user.spent}$</td>
            </tr>
          ))}
        </table>
      </div>
      <div className="pagination"></div>
    </div>
  );
}

function Statistic({ openStatistic }) {
  const [usersData, setUsersData] = useState([])
  const [oftenUserData, setMostUsed] = useState([])

  useEffect(() => {
    GetStatistic().then((response) => setUsersData(response.data))
    GetMostUsed().then((response) => setMostUsed(response.data));
  }, []);

  const [commonStats, setCommonStats] = useState(() => {
    const aggregatedStats = {
      Users: {
        text: "Пользователей",
        count: new Set(usersData.map((user) => user.username)).size,
      },
      Tokens: {
        text: "Токенов",
        count: usersData.reduce((acc, user) => acc + user.tokens, 0),
      },
      Requests: {
        text: "Запросов",
        count: usersData.reduce((acc, user) => acc + user.requests, 0),
      },
      Modes: {
        text: "Режимов",
        count: new Set(usersData.map((user) => user.assistant)).size,
      },
      Spend: {
        text: "Потрачено",
        count:
          usersData.reduce((acc, user) => acc + parseFloat(user.spent), 0) +
          "$",
      },
    };

    return [aggregatedStats];
  });

  useEffect(() => {
    document.body.classList.add("noscroll");
  }, []);
  return (
    <div
      className={openStatistic ? "Statistics activeStatistic" : "Statistics"}
    >
      <div className="container-stata">
        <CommonStata commonStats={commonStats} />
        <OftenStata oftenUserData={oftenUserData} />
        <AllStata
          usersData={usersData}
          setCommonStats={setCommonStats}
          commonStats={commonStats}
        />
      </div>
    </div>
  );
}

export default Statistic;
