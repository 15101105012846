import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Navigate } from "react-router-dom";

import "../../css/reset.css";
import "./user.css";

import ChatsContainer from "../../components/ChatsContainer.jsx";
import Aside from "../../components/ButtonsChats.jsx";
import CreateNewChat from "../../components/CreateNewChat.jsx";
import EditUserData from "../../components/EditUserData.jsx";
import { UserChats } from "../api.js";

function UserPart() {
  const [UserInfo] = useState(localStorage.getItem("username"));
  const [chats, setChats] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [disabledTextarea, setDisabledTextArea] = useState(true);
  const [newChatName, setNewChatName] = useState("");
  const [newChatStatus, setNewChatStatus] = useState(0);
  const [userSettingsStatus, setUserSettingsStatus] = useState(0);
  const [userSettings, setUserSetting] = useState([
    {
      Name: UserInfo,
      Mail: "example@egmail.com",
      TelegramStatus: "",
      Password: "123456789",
    },
  ]);
  const [activeAside, setActiveAside] = useState(false);

  useEffect(() => {
    if (UserInfo) {
      UserChats(UserInfo).then((response) => setChats(response.data));
    }
    document.body.classList.add("panel");
    setDisabledTextArea(true);
  }, [UserInfo]);

  const handleChatSelect = (chatId) => {
    setActiveChat(chatId);
    setDisabledTextArea(false);
    setActiveAside(!activeAside);
  };

  const handleOpenNewChat = () => {
    setNewChatStatus(1);
    let overlayFullScreen = document.createElement("div");
    overlayFullScreen.classList.add("overlayFullScreen");
    document.body.appendChild(overlayFullScreen);
  };

  const handleOpenUserSettings = () => {
    setUserSettingsStatus(1);
    const overlayFullScreen = document.createElement("div");
    overlayFullScreen.classList.add("overlayFullScreen");
    document.body.appendChild(overlayFullScreen);
  };

  if (!UserInfo) {
    return <Navigate to='/' />
  }

  return (
    <>
      <main className="user">
        <Helmet>
          <title>User</title>
        </Helmet>
        <Aside
          chats={chats}
          setChats={setChats}
          userSettings={userSettings}
          handleOpenNewChat={handleOpenNewChat}
          onChatSelect={handleChatSelect}
          activeAside={activeAside}
          setActiveAside={setActiveAside}
          handleOpenUserSettings={handleOpenUserSettings}
        />
        <ChatsContainer
          activeChat={activeChat}
          disabledTextarea={disabledTextarea}
          setDisabledTextArea={setDisabledTextArea}
          chats={chats}
          setChats={setChats}
        />
        <CreateNewChat
          chats={chats}
          setChats={setChats}
          newChatName={newChatName}
          setNewChatName={setNewChatName}
          newChatStatus={newChatStatus}
          setNewChatStatus={setNewChatStatus}
        />
        <EditUserData
          userSettingsStatus={userSettingsStatus}
          setUserSettingsStatus={setUserSettingsStatus}
          userSettings={userSettings}
          setUserSetting={setUserSetting}
          activeAside={activeAside}
          setActiveAside={setActiveAside}
        />
      </main>
    </>
  );
}

export default UserPart;
