import React, { useState, useRef, useEffect } from "react";
import { botAnswer } from "../pages/api";

function ChatsContainer({
  chats,
  setChats,
  activeChat,
  setDisabledTextArea,
  disabledTextarea,
}) {
  const [size, setSize] = useState(52);
  const [prevValue, setPrevValue] = useState("");
  const [disabledButton, setDisabled] = useState(1);

  const handleChangeSize = (event) => {
    const currentValue = event.target.value;
    const lineCount = currentValue.split("\n").length;
    const newSize = 52 + (lineCount - 1) * 20;
    setSize(newSize > 52 ? newSize : 52);
    setPrevValue(currentValue);
    setDisabled(currentValue.trim().length === 0);
  };

  const sendMessage = async() => {
    const messageText = prevValue.trim();

    if (!messageText) return;

    const activeChatIndex = chats.findIndex((chat) => chat.ID === activeChat);

    if (activeChatIndex < 0) return;

    const updatedChats = chats.map((chat, index) => {
      if (index === activeChatIndex) {
        return {
          ...chat,
          messages: [...chat.messages, { message: messageText, type: "user" }],
        };
      }
      return chat;
    });
    setPrevValue("");
    setChats(updatedChats);
    let botResponse = await botAnswer(messageText, activeChat);
    const updatedChats2 = chats.map((chat, index) => {
      if (index === activeChatIndex) {
        return {
          ...chat,
          messages: [...chat.messages, { message: messageText, type: "user" }, botResponse],
        };
      }
      return chat;
    });
    setChats(updatedChats2);
  };

  const endOfMessagesRef = useRef(null); // Создаем ref для прокрутки

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };


  useEffect(() => {
    scrollToBottom();
  }, [chats, activeChat]);

  return (
    <div className="chat">
      <div className="chatsContainer">
        {activeChat ? (
          chats
            .filter((chat) => chat.ID === activeChat)
            .map((chat) => (
              <div key={chat.ID} className="chat-current">
                {chat.messages.map((msg, index) => (
                  <div
                    key={index}
                    className={
                      msg.type === "user" ? "UserMsg message" : "BotMsg message"
                    }
                  >
                    <p>{msg.message}</p>
                  </div>
                ))}
                <div ref={endOfMessagesRef} />
              </div>
            ))
        ) : (
          <div className="selectChatMessage">
            <h2>Выберите чат</h2>
          </div>
        )}
      </div>
      <div className="sendMessageArea">
        <textarea
          onInput={handleChangeSize}
          value={prevValue}
          style={{ height: size + "px" }}
          cols="30"
          disabled={disabledTextarea}
          placeholder="Сообщение боту"
          rows="10"
        ></textarea>
        <button
          className="floatSend"
          onClick={sendMessage}
          disabled={disabledButton}
        >
          <img src={process.env.PUBLIC_URL + "/img/svg/send.svg"} alt="" />
        </button>
      </div>
    </div>
  );
}
export default ChatsContainer;
